import React, {useEffect, useRef, useState} from "react";
import {
    Button,
    Create,
    Edit,
    maxLength,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput,
    useDataProvider,
    useNotify,
    useRedirect,
    useRefresh,
} from "react-admin";
import RecommendedCarsEstimateList, {RecommendedCarsEstimateListFunctions} from "./RecommendedCarsEstimateList";
import {Box, Grid, Typography} from "@material-ui/core";
import {useParams} from "react-router-dom";
import {
    ImageDirectory,
    IS_DISPLAY,
    IS_NEW,
    IS_PUBLIC,
    MAX_FETCH_DATA_SIZE,
    RecommendedCarImageClass,
    ResourceNames,
} from '../../config/const'
import NumberInputCommon from "../../common/NumberInputCommonComponent";
import {DefaultProductFunctions, RecommendedCarsDefaultProducts} from "./RecommendedCarsDefaultProductsList";
import RecommendedCarsNonMasterDefaultProductList from "./RecommendedCarsNonMasterDefaultProductList";
import RecommendedCarsAvailableProductsList, {AvailableProductFunctions} from "./RecommendedCarsAvailableProductsList";
import GoBack from "../../common/GoBackComponent";
import {ActionToolbar} from "../../common/ActionToolbar";

import {useForm} from 'react-final-form';
import {TempFileData} from "../../modules/temporaryFileModule";
import {ListPhotosType} from "../../common/image/arrayUtil";
import Alert from '@material-ui/lab/Alert';
import {TextInputForJapanese} from "../../common/TextInputForJapanese";
import CarDetailField from "./CarDetailFieldComponent";
import RecommendedCarsImagesComponent from "./RecommendedCarsImagesComponent";
import {saveImages} from "../../common/image/databaseImageUtils";
import {ApiEstimate, ApiRecommendedCar, ApiRecommendedCarImage} from "../../openapi";
import ManuallyCostField from "./ManuallyCostField";
import {
    RecommendedCarDefaultVariantItem,
    RecommendedCarDefaultVariantItemFunctions
} from "./RecommendedCarDefaultVariantItemComponent";
import JNumberAndInsetComponent from "./RecommendedJNumberAndInsetComponent";
import RecommendedPrice from "./RecommendedPriceComponent";


export interface RecommendedCarsProps {
    recommendedCarId?: number;
}

// https://stackoverflow.com/a/62588403
interface RecommendedCarsFormProps extends Record<string, any> {
    type: string
}

const RecommendedCarsForm: React.FC<RecommendedCarsFormProps> = (props) => {
    let {id} = useParams<{ id: string }>();
    const availableProductRef = useRef<AvailableProductFunctions>(null);
    const defaultProductRef = useRef<DefaultProductFunctions>(null);
    const estimateRef = useRef<RecommendedCarsEstimateListFunctions>(null);
    const recommendedCarDefaultVariantItemRef = useRef<RecommendedCarDefaultVariantItemFunctions>(null);
    const [productInitialValues, setProductInitialValues] = useState<number[]>();
    const [availableProductInitialValues, setAvailableProductInitialValues] = useState<number[]>();
    const [open, setOpen] = useState(false);
    const dataProvider = useDataProvider();
    const redirect = useRedirect();
    const [pathData1, setPathData1] = useState(new Array<TempFileData>());
    const [pathData2, setPathData2] = useState(new Array<TempFileData>());
    const [pathCrop, setPathCrop] = useState(new Array<TempFileData>());
    const [images1, setImages1] = useState<ListPhotosType[] | undefined>();
    const [images2, setImages2] = useState<ListPhotosType[] | undefined>();
    const [selectedCarGradeIds, setSelectedCarGradeIds] = useState<number>();
    const [selectedCarGradeId, setSelectedCarGradeId] = useState<number>();
    const [recommendedImageId, setRecommendedImageId] = useState<number>();
    const [flagThumbnail, setFlag1] = useState<boolean>(false);
    const [flagBigImage, setFlag2] = useState<boolean>(false);
    const refresh = useRefresh();
    const [attachedFileCount, setAttachedFileCount] = useState<number>(0);
    const [uploadedFileCounter, setUploadedFileCounter] = useState<number>(0);
    const [reloadFlag, setReloadFlag] = useState<number>(0);
    const [noteReloadFlag, setNoteReloadFlag] = useState<number>();
    const [afterSaveReloadFlag, setAfterSaveReloadFlag] = useState<number>();
    const [initialFlag, setInitialFlag] = useState(false);
    const [estimateList, setEstimateList] = useState<ApiEstimate[] | undefined>();

    const [jNumberOfFrontWheel, setJNumberOfFrontWheel] = useState<string>();
    const [insetOfFrontWheel, setInsetOfFrontWheel] = useState<string>();
    const [jNumberOfRearWheel, setJNumberOfRearWheel] = useState<string>();
    const [insetOfRearWheel, setInsetOfRearWheel] = useState<string>();
    const [selectedCarGradeIdsRec, setSelectedCarGradeIdsRec] = useState<number[]>();
    const [variantItemInitialValues, setVariantItemInitialValues] = useState<number[]>();
    const [defaultProductIds, setDefaultProductItemIds] = useState<number[]>();
    const [defaultVariantItemIds, setDefaultVariantItemIds] = useState<number[]>();
    const [selectAffectPriceVariantItemIds, setSelectAffectPriceVariantItemIds] = useState<number[]>();
    const [displayAutoJNumber, setDisplayAutoJNumber] = useState<boolean>(false);

    const onUpload = () => {
        setUploadedFileCounter(uploadedFileCounter + 1)
    }

    const resetAll = () => {
        setRecommendedImageId(undefined);
        setPathData1([]);
        setPathData2([]);
        setPathCrop([]);
        setFlag1(false);
        setFlag2(false);
    }

    const checkUploadedSize = () => {
        if (attachedFileCount >= uploadedFileCounter) {
            setReloadFlag(reloadFlag + 1);
        }
    }

    const noteReloadFn = () => {
        console.log('note1', noteReloadFlag)
        console.log('note2', afterSaveReloadFlag)
        if (initialFlag && noteReloadFlag === undefined) {
            setNoteReloadFlag(0);
            setInitialFlag(false);
        }
        if (noteReloadFlag === afterSaveReloadFlag) {
            refresh(true);
        }
        setNoteReloadFlag(0);
        setInitialFlag(false);
    }

    const refreshFn = () => {
        if (noteReloadFlag) {
            setNoteReloadFlag(noteReloadFlag + 1)
        }
    };

    useEffect(checkUploadedSize, [uploadedFileCounter]);
    useEffect(noteReloadFn, [afterSaveReloadFlag]);
    useEffect(() => setInitialFlag(true), []);

    const handleSave = async ({...values}) => {
        console.log(values)
        // return
        setOpen(true);
        setAfterSaveReloadFlag(0);
        let counter = 0;
        let data: ApiRecommendedCar;
        if (props.type === 'create') {
            const recommendedCarsCreate = await dataProvider.create('recommendedCars', {data: {...values}});
            let count = 1
            counter = ++count;
            data = recommendedCarsCreate.data as ApiRecommendedCar;
        } else {
            delete values.set;
            const recommendedCarsUpdate = await dataProvider.update('recommendedCars', {
                id: values.id,
                data: {...values},
                previousData: {id: values.id}
            });
            data = recommendedCarsUpdate.data as ApiRecommendedCar;
        }
        await afterSave(data, counter, values).then(() => {
            resetAll();
            if (props.type === 'create' || (pathData1.length > 0 && (pathData2.length === 0 || pathCrop.length === 0) && !recommendedImageId)) {
                redirect(`/recommendedCars/${data.id}`)
                refresh();
            } else {
                setUploadedFileCounter(0);
                onUpload();
                refresh();
                setAfterSaveReloadFlag(afterSaveReloadFlag ? afterSaveReloadFlag + 1 : 1);
            }
        })
    };

    const afterSave = async (recommendedCars: ApiRecommendedCar, counter: number, {...values}) => {

        await saveImages(dataProvider, ResourceNames.RECOMMENDED_CAR_IMAGE_RESOURCE, {
            recommendedCarImageClass: RecommendedCarImageClass.RECOMMENDED_CAR_IMAGE_CLASS_1,
            recommendedCarId: recommendedCars.id,
            position: counter++,
        }, ImageDirectory.RECOMMENDED_CAR_IMAGE_CLASS_1 + recommendedCars.id + '/', pathData1, images1);
        setAttachedFileCount(pathData2.length);
        await saveImages(dataProvider, ResourceNames.RECOMMENDED_CAR_IMAGE_RESOURCE, {
            recommendedCarImageClass: RecommendedCarImageClass.RECOMMENDED_CAR_IMAGE_CLASS_2,
            recommendedCarId: recommendedCars.id,
            position: counter++,
            note: values.note,
        }, ImageDirectory.RECOMMENDED_CAR_IMAGE_CLASS_2 + recommendedCars.id + '/', pathData2, images2, pathCrop, flagThumbnail, flagBigImage, recommendedImageId, onUpload, refreshFn);
        if (values.note && recommendedImageId) {
            const recommendedCarImage = (await dataProvider.getOne(ResourceNames.RECOMMENDED_CAR_IMAGE_RESOURCE, {id: Number(recommendedImageId)})).data as ApiRecommendedCarImage;
            recommendedCarImage.note = values.note;
            await dataProvider.update(ResourceNames.RECOMMENDED_CAR_IMAGE_RESOURCE, {
                id: recommendedImageId,
                data: {...recommendedCarImage},
                previousData: {...recommendedCarImage}
            });
            refresh();
        }

        await estimateRef.current?.save(props.id, estimateList);
        await defaultProductRef.current?.save(recommendedCars.id, defaultProductIds!!);
        await availableProductRef.current?.save(recommendedCars.id, Array.from(new Set(values['availableProducts'])));
        await recommendedCarDefaultVariantItemRef.current?.save(recommendedCars.id!!, defaultProductIds!!, defaultVariantItemIds!!);
        setDisplayAutoJNumber(false);
    }

    return (
        <SimpleForm
            {...props}
            submitOnEnter={false}
            save={handleSave}
            toolbar={<ActionToolbar floatingRight={true}/>}
        >
            <Grid container={true} className={'w-100'} spacing={3}>
                <Grid item={true} xs={8}>
                    <Grid container={true}>
                        <RecommendedCarsEstimateList
                            recommendedCarId={props.id}
                            setEstimateList={setEstimateList}
                            ref={estimateRef}
                            {...props}
                        />
                    </Grid>
                </Grid>
                <Grid item={true} xs={4}>
                    <TextInput source="orderMemo" label={'備考欄'} multiline={true} rows={10} fullWidth={true}/>
                </Grid>
                <Grid item={true} xs={4}>
                    <BooleanInput source={'whetherPublic'} label={'公開'} myChoice={IS_PUBLIC} {...props}/>
                    <TextInputForJapanese source={'name'} label={'名称'} fullWidth={true} validate={[required()]}/>
                    <TextInputForJapanese
                        source={'chassis'}
                        label={'車台番号'}
                        fullWidth={true}
                        validate={[required(), maxLength(25)]}
                    />
                    <CarDetailField
                        selectedCarGradesIds={selectedCarGradeIds}
                        setSelectedCarGradesIds={setSelectedCarGradeIds}
                    />
                    <TextInputForJapanese source={'color'} label={'カラー'} fullWidth={true} validate={[required()]}/>
                    <BooleanInput source={'whetherNew'} label={'新着'} myChoice={IS_NEW} {...props}/>
                    <NumberInputCommon source="targetPrice" label={'予定売価(税抜)'}/>
                    <ManuallyCostField/>
                    <CalculatedFields type={props.type}
                                      defaultVariantItemIds={defaultVariantItemIds}
                                      productInitialValues={productInitialValues}
                                      selectAffectPriceVariantItemIds={selectAffectPriceVariantItemIds}
                    />

                    <NumberInputCommon source="taxByEnvironmentalPerformancesAmount"
                                       label={'環境性能割'}/>
                    <NumberInputCommon source="weightTaxPrice" label={'重量税'}/>
                    <NumberInputCommon source="carInsuranceAmount" label={'自賠責保険'}/>
                    <NumberInputCommon source="usedCarMaintenanceFee" label={'中古車整備費用'}/>
                    <NumberInputCommon source="position" label={'表示順序'}/>
                    <TextInputForJapanese source="dateOfFirstRegistration" label={'初年度登録'} fullWidth={true}/>
                    <NumberInputCommon source="mileage" label={'走行距離'}/>
                    <TextInputForJapanese source="carInspection" label={'車検'} fullWidth={true}/>
                    <ReferenceInput
                        label="販売店舗"
                        source="shopId"
                        reference="shops"
                        sort={{field: 'position', order: 'ASC'}}
                        perPage={MAX_FETCH_DATA_SIZE}
                    >
                        <SelectInput source={'name'} fullWidth={true}/>
                    </ReferenceInput>
                    <TextInputForJapanese source="suspensionName" label={'装着済サスペンション'} fullWidth={true}/>
                    <TextInputForJapanese source="repairHistory" label={'修復歴'} fullWidth={true} multiline={true}
                                          rows={10}/>
                    <TextInputForJapanese source="explanation" label={'説明文'} fullWidth={true} multiline={true}
                                          rows={10}/>
                    <TextInputForJapanese source="memo" label={'注意事項'} fullWidth={true} multiline={true}
                                          rows={10}/>
                    <SelectInput
                        label={'総合サイトトップに表示'}
                        choices={choices(IS_DISPLAY)!}
                        source={'showCompanySite'}
                        style={{width: '100%'}}
                    />
                </Grid>
                <Grid item={true} xs={8}>
                    <RecommendedCarsDefaultProducts
                        carGradeIds={selectedCarGradeId}
                        setSelectedCarGradeId={setSelectedCarGradeId}
                        ref={defaultProductRef}
                        recommendedCarId={props.id}
                        productInitialValues={productInitialValues}
                        setProductInitialValues={setProductInitialValues}
                        open={open}
                        setOpen={setOpen}
                        defaultProductIds={defaultProductIds}
                        setDefaultProductIds={setDefaultProductItemIds}
                    />

                    <Box mt={2}>
                        {id !== undefined &&
                            <RecommendedCarsNonMasterDefaultProductList recommendedCarId={id ? Number(id) : 0}/>
                        }
                        {id === undefined &&
                            <Alert severity="warning">マスタ外商品は特選車を保存後に登録頂けます。</Alert>
                        }
                    </Box>
                    <RecommendedCarsAvailableProductsList
                        carGradeIds={selectedCarGradeId}
                        setSelectedCarGradeId={setSelectedCarGradeId}
                        ref={availableProductRef}
                        recommendedCarId={props.id}
                        availableProductInitialValues={availableProductInitialValues}
                        setAvailableProductInitialValues={setAvailableProductInitialValues}
                        open={open}
                        setOpen={setOpen}
                    />
                    <RecommendedCarDefaultVariantItem
                        ref={recommendedCarDefaultVariantItemRef}
                        carGradeIds={selectedCarGradeIdsRec}
                        recommendedCarId={props.id}
                        open={open}
                        setOpen={setOpen}
                        variantItemInitialValues={variantItemInitialValues}
                        setVariantItemInitialValues={setVariantItemInitialValues}
                        defaultVariantItemIds={defaultVariantItemIds}
                        setDefaultVariantItemIds={setDefaultVariantItemIds}
                        defaultProductIds={defaultProductIds}
                        selectAffectPriceVariantItemIds={selectAffectPriceVariantItemIds}
                        setSelectAffectPriceVariantItemIds={setSelectAffectPriceVariantItemIds}
                        setJNumberOfFrontWheel={setJNumberOfFrontWheel}
                        setInsetOfFrontWheel={setInsetOfFrontWheel}
                        setJNumberOfRearWheel={setJNumberOfRearWheel}
                        setInsetOfRearWheel={setInsetOfRearWheel}
                        setDisplayAutoJNumber={setDisplayAutoJNumber}
                        displayAutoJNumber={displayAutoJNumber}
                    />

                    <JNumberAndInsetComponent
                        jNumberOfFrontWheel={jNumberOfFrontWheel}
                        insetOfFrontWheel={insetOfFrontWheel}
                        jNumberOfRearWheel={jNumberOfRearWheel}
                        insetOfRearWheel={insetOfRearWheel}
                        displayAutoJNumber={displayAutoJNumber}
                    />
                    <RecommendedCarsImagesComponent
                        dataProvider={dataProvider}
                        recommendedCarId={props.id}
                        images1={images1}
                        images2={images2}
                        setImages1={setImages1}
                        setImages2={setImages2}
                        pathData1={pathData1}
                        pathData2={pathData2}
                        setPathData1={setPathData1}
                        setPathData2={setPathData2}
                        setPathCrop={setPathCrop}
                        maxFileCount1={2}
                        maxFileCount2={1}
                        setImageId={setRecommendedImageId}
                        recommendedImageId={recommendedImageId}
                        setThumbnailUpdating={setFlag1}
                        setBigImageUpdating={setFlag2}
                        updateRefresh={reloadFlag}/>
                </Grid>
            </Grid>
        </SimpleForm>
    )
}


interface CalculatedFieldsProps {
    type: string;
    defaultVariantItemIds: number[] | undefined;
    productInitialValues: number[] | undefined
    selectAffectPriceVariantItemIds: number[] | undefined;
}

const CalculatedFields: React.FC<CalculatedFieldsProps> = (props) => {
    const dataProvider = useDataProvider();
    const form = useForm();
    const notify = useNotify();


    const calculate = () => {
        const values = form.getState().values;
        dataProvider.calculate('recommendedCars', {data: {...values}}).then((converted: any) => {
            form.change("carDiscountPrice", converted.data['carDiscountPrice']);
            form.change("totalProductPrice", converted.data['totalProductPrice']);
            form.change("totalPrice", converted.data['totalPrice']);
            form.change("totalPriceWithTax", converted.data['totalPriceWithTax']);
            form.change("totalCost", converted.data['totalCost']);
            form.change("benefit", converted.data['benefit']);
        })
    }

    const calculatePrice = () => {
        const values = form.getState().values;
        const totalProductPrice = values['totalProductPrice'];
        const targetPrice = values['targetPrice'];
        const totalVariantItemPrice = values['totalVariantItemPrice'] || 0;
        if (totalProductPrice == null || targetPrice == null) {
            alert('計算に必要なデータが未入力です');
            return;
        }
        const price = targetPrice - totalProductPrice - totalVariantItemPrice;
        form.change("price", price);
        calculate();
        notify(`計算が完了しました`);
    }

    const copyOriginalPrice = () => {
        const values = form.getState().values;
        form.change('originalPrice', values['price']);
    }

    return (
        <>
            {props.type === 'update' &&
                <Grid container={true}>
                    <Grid item={true} xs={6}>
                        <Button
                            label={'予定売価から車両売価を計算'}
                            variant="contained"
                            color={'default'}
                            onClick={calculatePrice}
                        />
                    </Grid>
                    <Grid item={true} xs={6}>
                        <Button
                            label={'利益など計算'}
                            variant="contained"
                            color={'default'}
                            onClick={calculate}
                        />
                    </Grid>
                </Grid>
            }
            <NumberInputCommon source="price" label={'車両売価'}/>
            <Button
                label={'↓コピー'}
                variant="contained"
                color={'default'}
                onClick={copyOriginalPrice}
            />
            <NumberInputCommon source="originalPrice" label={'車両定価'}/>
            <NumberInputCommon source="purchasePrice" label={'仕入れ金額'}/>

            {props.type === 'update' &&
                <>
                    <NumberInputCommon source="totalCost" label={'原価合計'} disabled={true}/>
                    <NumberInputCommon source="carDiscountPrice" label={'車両値引き額'} disabled={true}/>
                    <NumberInputCommon source="totalProductPrice" label={'装備品売価合計'} disabled={true}/>
                    <RecommendedPrice
                        defaultVariantItemIds={props.defaultVariantItemIds}
                        productInitialValues={props.productInitialValues}
                        selectAffectPriceVariantItemIds={props.selectAffectPriceVariantItemIds}
                    />
                    <NumberInputCommon source="totalPrice" label={'売価合計'} disabled={true}/>
                    <NumberInputCommon source="totalPriceWithTax" label={'税込売価合計'} disabled={true}/>
                    <NumberInputCommon source="benefit" label={'利益'} disabled={true}/>
                </>
            }
        </>

    )
};

interface NoteProps {
    imageId?: number;
}

export const NoteField: React.FC<NoteProps> = (props) => {
    const dataProvider = useDataProvider();
    const form = useForm();

    const loadNote = () => {
        if (props.imageId) {
            const fn = async () => {
                const noteData = await dataProvider.getOne(ResourceNames.RECOMMENDED_CAR_IMAGE_RESOURCE, {id: Number(props.imageId)});
                if (noteData) {
                    form.change("note", noteData.data.note);
                }
            }
            fn().then();
        }
    };
    useEffect(loadNote, [props.imageId]);
    return (
        <>
            <Typography component={'h6'}>コメント</Typography>
            <TextInputForJapanese source="note" validate={props.imageId ? [required()] : []} label={'コメント'} multiline
                                  rows={20} fullWidth/>
        </>
    )
}


export interface ChoiceProps {
    id?: number;
    name?: string
}

const choices = (data: Array<ChoiceProps>) => {
    const dataChoices = data.map((item: ChoiceProps) => {
        const myChoices: ChoiceProps = {id: item.id, name: item.name}
        return myChoices;
    })
    return dataChoices;
}

export const RecommendedCarsEdit: React.FC = (props) => (
    <Edit
        {...props}
        mutationMode={'optimistic'}
        className={'w-125'}
        actions={<ActionTopToolbar/>}
    >
        <RecommendedCarsForm {...props} type={'update'}/>
    </Edit>
);

export const RecommendedCarsCreate: React.FC = (props) => {
    return (
        <Create
            {...props}
            actions={<ActionTopToolbar/>}
        >
            <RecommendedCarsForm {...props} type={'create'}/>
        </Create>
    )
}
const ActionTopToolbar = () => {
    return (
        <div className={'mb-30 '}>
            <GoBack url={'/recommendedCars'}/>
        </div>
    );
};


const BooleanInput = ({...props}) => {
    props.record.whetherPublic = +props.record.whetherPublic
    props.record.whetherNew = +props.record.whetherNew
    props.record.showCompanySite = +props.record.showCompanySite
    const {label, source} = props
    return (
        <SelectInput
            label={label}
            choices={choices(props.myChoice)!}
            source={source}
        />
    );
};
